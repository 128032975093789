p{
    font-family: 'Inter';
font-style: normal;
color: #000000;
font-weight: 600;
font-size: 11.26px;
line-height: 14px;
}
.sub-head{
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 15.26px;
line-height: 18px;

color: #000000;
}
.homework-image-section {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 20px 0;
}
.homework-image-section h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 28.26px;
    line-height: 34px;
    color: #000000;
}
.short-main {
    display: grid;
    gap: 20px;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 25px;
}
.short-main label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11.26px;
    line-height: 14px;
    color: #000000;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.short-main .short-input, .short-main span {
    background: #FFFFFF;
    border: 1px solid #A9A9A9;
    border-radius: 8px;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}
input[type=file]{
        background: transparent;
        border: none;
        border-radius: 0;
}
input{
    background: #FFFFFF;
border: 1px solid #A9A9A9;
border-radius: 8px;
}