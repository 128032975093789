.reset-block .input-head {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 10px;
}
.reset-block {
    padding: 20px 0;
}
.submit-btn button {
    background: #FF4F5A;
    border: none;
    width: 48%;
}
.submit-btn {
    padding: 10px;
    text-align: right;
}