body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h2{
  font-family:'Inter', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 54px;
color: #181D2D;
}
p{
  font-family: 'Inter', sans-serif;;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 27px;

color: #787878;
}