.search-filter {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.addnote-child {
    background: #FFFFFF;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.21);
    border-radius: 10.8152px;
    padding: 20px;
}
.addnotes-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 30px    ;
}
.addnote-child h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #0B394E;
    margin-bottom: 25px;
}
.addnote-child p{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;
color: #7A93A7;

}
.timing {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.timing p {
    margin: 0;
}
.timing button {
    background: transparent;
    border: none;
    padding: 0;
}
.timing .btn:hover {
    color: transparent;
    background-color: transparent;
    border-color: transparent;
}
.addone-value p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12.93px;
    line-height: 27px;
    color: #FF4F5A;
    margin: 0;
}
.addone-value{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background: #F5FBFF;
    border: 1.3519px solid #FF4F5A;
    border-radius: 10.8152px;
    box-shadow: none;
}
.short button{
    background: transparent;
    border: none;
}
.calendar-modal .modal-dialog{
    max-width: 400px !important;
  }
  .calendar-modal .modal-content{
    padding: 20px;
  }
  .fc-scroller {
    height: auto!important;
    overflow-y: auto;
}

.main-calender-cst{
    border-top: 2px solid #c1c0c0;
    margin-top: 30px;
    padding-top: 13px;
}

.main-calender-cst .fc-media-screen .fc-header-toolbar button{
    background: none;
    color: #000;
    border: 1px solid #000;
    font-size: 15px;
    padding: 6px 10px;
}

.main-calender-cst .fc-media-screen .fc-header-toolbar button.fc-button-active{
    background-color: #0b394e;
    border-color: #0b394e;
}

.main-calender-cst .fc-media-screen .fc-header-toolbar>.fc-toolbar-chunk .fc-prev-button,
.main-calender-cst .fc-media-screen .fc-header-toolbar>.fc-toolbar-chunk .fc-next-button{
    border: none;
    margin-left: 0;
}

.main-calender-cst .fc-media-screen .fc-header-toolbar>.fc-toolbar-chunk .fc-today-button{
    margin-right: 20px;
}

.main-calender-cst .fc-media-screen .fc-header-toolbar>.fc-toolbar-chunk h2{
    font-size: 22px;
}

.main-calender-cst .fc-media-screen .fc-view-harness table a{
    color: #4e4e4e;
    text-decoration: none;
    font-weight: 400;
}

.main-calender-cst .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk:nth-child(2){
    margin-right: auto;
    padding-left: 22px;
}

.main-calender-cst .fc-media-screen .fc-view-harness{
    height: 100vh !important;
}


.calendar-modal .modal-content{
   padding: 0;
}

.calendar-modal .modal-content .top-hd-cross{
    background: #F1F3F4;
    display: flex;
    justify-content: flex-end;
    padding: 8px 6px;
}

.calendar-modal .modal-content .top-hd-cross button img{
    width: 15px;
}

.calendar-modal .modal-content label.date-mn-cst{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.calendar-modal .modal-content form{
    padding: 8px 21px 40px;
}

.calendar-modal .modal-content form label,
.calendar-modal .modal-content form input,
.calendar-modal .modal-content form select{
    width: 100% !important;
}

.calendar-modal .modal-content form label{
    margin-bottom: 5px;
}

.calendar-modal .modal-content form input{
    margin-bottom: 20px;
    padding: 10px 10px;
    font-size: 14px;
}

.calendar-modal .modal-content form input:focus-visible{
    border: 1px solid #A9A9A9;
}

.calendar-modal .modal-content form select{
    margin-bottom: 20px;
    padding: 10px 10px;
    font-size: 14px;
}

.calendar-modal .modal-content form button{
    margin-top: 30px;
    font-size: 17px;
    font-weight: 700;
}




.event-style {
    background: #ff4f5a;
    border: 1px solid #ff4f5a;
}
/* responsive work  ***************************************************************************/
@media(max-width:992px){
    .addnotes-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  }
  @media screen and (max-width: 767px){
    .addnotes-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
  }
}
@media  screen and (max-width:576px) {
    .addnotes-wrapper {
        grid-template-columns: 1fr 1fr ;
    }
    .search-filter {
        gap: 10px;
    }
    .search-filter input {
        width: 60%;
    }
}

.search-filter .short{
    color: #8A8A8A;
    font-size: 13px;
    font-weight: 600;
}


@media(max-width:480px){
    .main-calender-cst .fc-media-screen .fc-header-toolbar {
        flex-direction: column;
    }

    .main-calender-cst .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk:nth-child(2){
        margin: 0 auto;
    }
}