.note-head{
    margin-top: 45px;
    margin-bottom: 150px !important;

}
.note-head p{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 11.26px;
line-height: 14px;
color: #000000;

}
.note-head .textbox{
    background: #FFFFFF;
border: 1px solid #A9A9A9;
border-radius: 8px;
margin-bottom: 20px;
}
.checkbox-p{
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 12.26px;
line-height: 15px;
color: #000000;
}
.note-head label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11.26px;
    line-height: 14px;
    color: #000000;
    display: flex;
    gap: 7px;
    width: 95px;
}
.checkbox-perent{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 60px;
}
