textarea {
    width: 80%;
    height: 200px;
}
.therepy-headings {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-top: 25px;
    margin-bottom: 15px;
}
.therepy-select{
    appearance: auto;
}

.search-filter input{
    width: 308px;
    height: 32px;
    padding: 0px 31px;
    font-size: 14px;
}

.search-filter input::placeholder{
  opacity: 1 !important;
  color: #0B394E !important;
}