.radio-input-head{
    position: relative;
    margin-bottom: 40px;
}
.radio-input-head:before {
    content: "";
    top: 7px;
    left: 0;
    position: absolute;
    max-width: 410px;
    width: 100%;
    height: 1px;
    background: #000;
    z-index: -1;
}
.radio-input-head .bullet-div {
    margin-right: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9.26px;
    line-height: 11px;
    color: #000000;
    gap: 6px;
}
.radio-input-head .bullet-div  input[type='radio']{
    width: 15px;
    height: 15px;
    accent-color: #FF4F5A;
    }
    .btn-save {
        background: #FF4F5A;
        border-radius: 6px;
        border: none;
        margin-right: 0px;
    }
.custom_symtom_section h4{
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #0B394E;
      
    }

    .input_symptom {
display: flex;
        align-items: center;
        margin-top: 40px;
    }
    .input_symptom span{
        font-weight: 600;
        font-size: 16.26px;
        line-height: 20px;
        color: #000000;
        padding-right: 4px;
    }
    .input_symptom input{
        border: 1px solid #A9A9A9;
    border-radius: 6px;
    height: 35px;
        width: 315px;

    }
    .input_symptom .btn-add{
        background: #0B394E;
    border-radius: 6px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    padding: 8px 60px;
    height: 35px;
    right: 38px;
    }
    .symptom {
        align-items: center;
        border-bottom: 1px solid #C2C2C2;
        gap: 24px;
    }

    /* .symptom img{
        width: 400px;
        height: 400px;
    } */
    .custom_symptom_form {
        padding-top: 30px;
        padding-bottom: 100px;
    }
    .custom_symptom_form label.form-label, .custom_symptom_form h4{
        font-size: 16.26px;
        color: #000;
        font-weight: 600;
    }
    .custom_symptom_form p{
        color: #585858;
        font-size: 10.26px;
    }

    .custom_symptom_form .btn-save{
        margin-right: 15px;
    }

    .custom_symptom_form select#my_multiselect_field {
        appearance: auto;
    }