.topleft {
    
   
    font-size: 18px;
  }
  .input-feilds {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 19px;
    margin-bottom: 25px;
 
}
  
  .input-feilds > div {
  flex: 50%; 
}
  input, label {
    display:block;
}
.profile-img {
  width: 132px;
  border-radius: 50%;
  height: 132px;
  border: 3px solid #FF4F5A;
  object-fit: cover;
}
  .mainContainer{
    position: absolute;
  }
  .form-devider {
    display: flex;
    width: 100%;
    padding: 0 0 0 50px;
    position: relative;
}
.user-image {
  transform: translate(0px, -85px);
}
.input-feilds label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  width: 34%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}


.input-feilds label input {
  border: 1px solid #A9A9A9;
  border-radius: 8px;
  height: 35px;
}

/******* 21-02-2023 ******/

.input-feilds button{
  background: #0B394E;
  border: none;
  width: 100%;
}

.user-image label img {
  height: 24px;
  width: 24px;
}
.user-image label{
  margin-top: 4px;
  display: flex;
  width: 101%;
}
.user-image{
  text-align: center;
}

.form-devider .input-feilds label{
  width: 41%;
}
.form-devider input#upload-img{
  width: 100%;
  padding: 0px 14px;
  height: 40px;
  font-size: 13px;
  margin-top: 4px;
}
.form-devider .input-feilds button{
  height: 40px;
}
.toaster {
  position: fixed;
  top: 20px;
 
  left: 80%;
  transform: translateX(-50%);
  background-color: #FF4F5A;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.toaster.show {
  opacity: 1;
}
