.container-fluid{
  max-width: 1300px;
  margin: 0 auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.sidenav {
  height: 55%; /* Full-height: remove this if you want "auto" height */
  width: 160px; /* Set the width of the sidebar */
   /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 80px; /* Stay at the top */
  left: 0;
  background-color: #0B394E;

  color: #fff;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}
.main {
  padding: 0px 0px 0 10px;
  height: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.sidenav {
  height: 100%;
  width: 220px;
}
.content-main {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  margin-bottom: 25px;
}
.user-profile{
  background: #E1EEF4;
    box-shadow: 0px 4px 14px rgb(0 0 0 / 10%);
    border-radius: 6px;
    font-family:'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #0B394E;
    padding: 20px;
}
label.custom-file-upload.fas {
  width: 100%;
}
.btn-add{
  position: absolute;
width: 179px;
height: 34px;

color: #fff;
background: #0B394E;
border-radius: 6px;
}

.btn-save{
  color: #fff;
  background: #FF4F5A;
width: 179px;
height: 40px;


}
.topleft {
  background: #0B394E;
  box-shadow: 0px 7px 23px rgba(18, 18, 18, 0.13);
  border-radius: 0px 0px 30px 30px;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
    padding: 40px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* responsive ************************************************************************* */
@media(max-width:576px){
  .content-main {
    grid-template-columns: 1fr;
}
.main {
  padding: 0px;
  height: 100%;
}
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

