.side-bar-user-icon {
    display: flex;
    gap: 10px;
    align-items: center;
    align-content: center;
    margin: 0px 1rem 1rem;
    padding-bottom: 15px;
    border-bottom: 1px solid #1C5067;
}
.side-bar-user-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #fff;
}
.side-bar-user-icon p{
    /* font-family: 'Plus Jakarta Sans'; */
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
color: #FF4F5A;
margin: 0;
}
.side-bar-user-icon p span {
    color: #fff;
}
.sidenav {
    background: #0B394E;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
    border-radius: 13px;
    padding: 20px 0;
    width: 265px;
}
.sidenav a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #FFFFFF;
    padding: 0px 0px 14px !important;
    border-bottom: 1px solid #153F52;
    display: flex;
    justify-content: space-between;
    margin: 0px 1rem 1rem;
}